import React, { Fragment, useEffect, useState } from "react";
import { GoogleSpreadsheet } from "google-spreadsheet";

const doc = new GoogleSpreadsheet(
  "1oFDveSz0CytLmHR2_36bL3glnyrfzphOhUQ4xRE0gj4"
);

const Social = () => {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    async function fetchData() {
      // Initialize Auth - see https://theoephraim.github.io/node-google-spreadsheet/#/getting-started/authentication
      await doc.useServiceAccountAuth({
        // env var values are copied from service account credentials generated by google
        // see "Authentication" section in docs for more info
        client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/gm,
          "\n"
        ),
      });

      let loadedInfo = await doc.loadInfo(); // loads sheets

      const newSheet = await doc.sheetsByIndex[0];
      const fetchedData = await (await newSheet.getRows()).map(
        (item, i) => item["_rawData"]
      );
      setData(fetchedData);
      console.log((await newSheet.getRows())[0]["_rawData"]);
      setHeaders(await newSheet.headerValues);
    }

    fetchData();
  }, []);

  if (!data.length || !headers.length) {
    return <div>Loading</div>;
  }

  return (
    <>
      <h1>Fetched from Google Sheets</h1>
      <ul>
        {[headers, ...data].map((item, i) => (
          <Fragment key={i}>
            <div
              style={{
                ...(i == 0 ? style.header : {}),
                ...style.outer,
              }}
            >
              <div>{item[0]}</div>
              <div>{item[1]}</div>
              <div>{item[2]}</div>
              {i === 0 ? (
                <div>{item[2]}</div>
              ) : (
                <div>
                  <div
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                      height: 50,
                      width: 50,
                    }}
                  >
                    <img
                      alt={JSON.stringify(item[3])}
                      src={item[3]}
                      height={50}
                      width={50}
                    />
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        ))}
      </ul>
    </>
  );
};

const style = {
  header: {
    background: "green",
    color: "white",
  },
  outer: {
    display: "grid",
    gridTemplateColumns: "200px 200px 100px 100px",
    gridTemplateRows: "40px 10px",
    border: "2px solid gray",
    marginTop: -2,
    padding: 10,
    marginRight: 40,
  },
  inner: {},
};

export default Social;
