import React, { Fragment, useEffect, useState } from "react";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { ViewLarge, ViewMedium, ViewSmall, Viewport } from "../hooks/viewport";
import LargeView from "../components/FoodService/LargeView";
import SmallView from "../components/Card/SmallView";

const doc = new GoogleSpreadsheet(
  "1oFDveSz0CytLmHR2_36bL3glnyrfzphOhUQ4xRE0gj4"
);

const tableToJson = (table) => {
  console.log(table);
  var data = [];
  for (let i = 1; i < table.length; i++) {
    let item = {};
    for (let j = 0; j < table[0].length; j++) {
      item[table[0][j]] = table[i][j];
    }
    data.push(item);
  }
  return data;
};

const FoodService = () => {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await doc.useServiceAccountAuth({
        // env var values are copied from service account credentials generated by google
        // see "Authentication" section in docs for more info
        client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/gm,
          "\n"
        ),
      });

      await doc.loadInfo(); // loads sheets

      const newSheet = await doc.sheetsByIndex[1];
      const fetchedData = await (
        await newSheet.getRows()
      ).map((item, i) => item["_rawData"]);
      const headerValues = await newSheet.headerValues;
      const mapTable = tableToJson([headerValues, ...fetchedData]);
      mapTable.sort((a, b) =>
        a[headerValues[0]] > b[headerValues[0]] ? 1 : -1
      );
      setHeaders(headerValues);
      setData(mapTable);
    }

    fetchData();
  }, []);

  return (
    <>
      <Viewport>
        <ViewLarge>
          <div style={{ margin: 60 }}>
            <LargeView data={data} headers={headers} />
          </div>

          {/* <ul>
            Food Service
            {[headers, ...data].map((item, i) => (
              <Fragment key={i}>
                <div
                  style={{
                    ...(i == 0 ? style.header : {}),
                    ...style.outer,
                  }}
                >
                  <div>{item[0]}</div>
                  <div>{item[1]}</div>
                  <div>{item[2]}</div>
                  {i === 0 ? (
                    <div>{item[2]}</div>
                  ) : (
                    <div>
                      <div
                        style={{
                          borderRadius: "50%",
                          overflow: "hidden",
                          height: 50,
                          width: 50,
                        }}
                      >
                        <img
                          alt={JSON.stringify(item[3])}
                          src={item[3]}
                          height={50}
                          width={50}
                        />
                      </div>
                    </div>
                  )}
                  <div>{item[4]}</div>
                  <div>{item[5]}</div>
                  <div>{item[6]}</div>
                </div>
              </Fragment>
            ))}
          </ul> */}
        </ViewLarge>
        {/* <ViewMedium>Medium View</ViewMedium> */}
        <ViewSmall>
          <div style={{ margin: 7, paddingTop: 2 }}>
            {data.map((item) => {
              return <SmallView data={item} headers={headers} />;
            })}
          </div>
        </ViewSmall>
      </Viewport>
    </>
  );
};

const style = {
  header: {
    background: "green",
    color: "white",
  },
  outer: {
    display: "grid",
    gridTemplateColumns: "200px 200px 200px 200px 200px 200px",
    gridTemplateRows: "100px auto",
    border: "2px solid gray",
    marginTop: -2,
    padding: 10,
    marginRight: 40,
  },
  inner: {},
};

export default FoodService;
