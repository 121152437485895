import React from "react";

const Gallery = () => {
  return (
    <div>
      <h1>Page with Pictures</h1>
    </div>
  );
};

export default Gallery;
